@import '../../common/styles/variables';

.list {
  margin-bottom: calculateRem(20);

  @media (min-width: $desktop) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
