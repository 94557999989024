@import '../../styles/variables';

.dappListItem {
  transition-property: opacity;
  transition-duration: 1s;
  width: calculateRem(330);
}

.dappListItem.transparent {
  opacity: 0;
}

.listItem {
  font-family: $font;
  background: $background;
  display: flex;
  margin: 0 calculateRem(16) 0 calculateRem(16);
  position: relative;
}

.rankedListItem {
  font-family: $font;
  background: $background;
  display: flex;
  margin: 0 0 0 0.5rem;
  position: relative;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.header {
  color: $headline-color;
  font-size: calculateRem(15);
  line-height: calculateRem(22);
  margin-bottom: calculateRem(2);
  margin-top: calculateRem(12);
  font-weight: 500;
  cursor: pointer;
}

.logo {
  display: flex;
  align-items: center;
  padding: 0 calculateRem(20);
}

.image {
  max-width: calculateRem(40);
  max-height: calculateRem(40);
  margin-top: calculateRem(10);
  margin-right: calculateRem(16);
  border-radius: 50%;
  cursor: pointer;
}

.review_icon {
  margin-left: calculateRem(-30);
  margin-top: calculateRem(40);
}

.url {
  font-size: calculateRem(12);
  color: $link-color;
  text-decoration: none;
  margin-bottom: 12px;
}

.description {
  color: $text-color;
  font-size: calculateRem(13);
  line-height: calculateRem(18);
  margin-bottom: calculateRem(2);
  margin-top: 0;
  max-height: calculateRem(40);
  overflow-y: hidden;
  cursor: pointer;

  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.position {
  flex: 0 0 auto;
  margin-top: calculateRem(30);
  margin-right: calculateRem(16);
  font-size: calculateRem(13);
  color: $headline-color;
}

.imgWrapper {
  flex: 0 0 auto;
}

.sntAmount {
  font-size: calculateRem(12);
  font-weight: 500;
  // width: 80px;
  display: inline-block;
  color: $headline-color;
  margin-right: calculateRem(40);
}

.sntAmount img {
  vertical-align: middle;
  margin-right: calculateRem(6);
}

.vote {
  font-size: calculateRem(11);
  text-transform: uppercase;
  color: $link-color;
  font-weight: 600;
  text-decoration: none;
  letter-spacing: 0.3;
  font-family: $font;
  // width: calculateRem(80);
  display: inline-block;
  cursor: pointer;
}

.vote:not(:last-child) {
  margin-right: calculateRem(12);
}

.vote img {
  vertical-align: middle;
  margin-right: calculateRem(2);
}

.actionArea {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: calculateRem(6);

  .voteTriggers {
    display: inherit;
    align-items: inherit;
  }
}

.actionArea > * {
  margin-bottom: calculateRem(8);
}
