@import '../../styles/variables';

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 4096;

  transition-property: opacity;
  transition-duration: 0.25s;

  .window {
    width: 100%;
    line-height: normal;
    position: relative;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 0px 2px 16px rgba(0, 9, 26, 0.12);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .close {
    width: 24px;
    height: 24px;
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 8px;
    top: 8px;
    color: #fff;
    font-family: 'Times New Roman', Times, serif !important;
    font-size: 22px;
    font-weight: 700;
    border-radius: 50%;
    background: #939ba1;
    cursor: pointer;
    transform: rotate(45deg);
    z-index: 999;
    position: sticky;
    margin-left: 90%;
  }
}

.wrapper.active {
  pointer-events: auto;
  opacity: 1;
}

@media (min-width: $desktop) {
  .wrapper {
    .window {
      width: 400px;
      max-height: 90%;
    }

    .close {
      display: flex;
    }
  }
}
