@font-face {
  font-family: 'EudoxusSans';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/EudoxusSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'EudoxusSans';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/EudoxusSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'EudoxusSans';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/EudoxusSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'EudoxusSans';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/EudoxusSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'EudoxusSans';
  font-style: italic;
  font-weight: 700;
  src: url('/fonts/EudoxusSans-Bold.ttf') format('truetype');
}
