@import '../../common/styles/variables';

.header {
  display: flex;
  justify-content: space-between;
  margin: calculateRem(15);
  align-items: center;
}

.headline {
  font-family: $font;
  color: $headline-color;
  font-size: calculateRem(17);
  margin: 0;
}

.categories {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  margin: 0 calculateRem(10) calculateRem(30) calculateRem(10);

  @media (min-width: $desktop) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: unset;
  }
}

.category {
  background: $background;
  font-family: $font;
  font-size: calculateRem(13);
  line-height: calculateRem(16);
  color: $headline-color;
  border: none;
  border-radius: calculateRem(12);
  padding: calculateRem(12);
  margin: calculateRem(4);
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  flex-direction: column;
  display: inline-grid;
  justify-items: center;

  p {
    margin-bottom: 0;
    font-weight: 500;
  }
}

.EXCHANGES {
  background: $purple-bg;
}

.DEFI {
  background: $dark-blue-bg;
}

.MARKETPLACES {
  background: $orange-bg;
}

.OTHER {
  background: $yellow-bg;
}

.MEDIA {
  background: $yellow-bg;
}

.CRYPTO_ONRAMPS {
  background: $navy-bg;
}

.GAMES {
  background: $pink-bg;
}

.COLLECTIBLES {
  background: $blue-bg;
}

.SOCIAL_NETWORKS {
  background: $green-bg;
}

.UTILITIES {
  background: $red-bg;
}
