@import '../../common/styles/variables';

.modalWindow {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: $desktop) {
  .modalWindow {
    height: auto;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

@media (min-width: $desktop) {
  .modalContent {
    height: 512px;
  }
}

.tabs {
  width: 100%;
  border-bottom: 1px solid $link-color;
  text-align: center;
  flex: 0;
}

.tabs button {
  color: #939ba1;
  position: relative;
  background: transparent;
  border: none;
  text-transform: uppercase;
  font-family: $font;
  font-size: calculateRem(13);
  font-weight: 500;
  height: calculateRem(40);
  letter-spacing: calculateRem(0.2);
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  outline: none;

  transition-property: color;
  transition-duration: 0.4s;
}

.tabs button:first-child {
  margin-right: 32px;
}

.tabs button:after {
  display: block;
  clear: both;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 24px;
  border-bottom: 2px solid transparent;
  margin: 0 auto 0 auto;

  transition-property: border-color;
  transition-duration: 0.4s;
}

.tabs button.active {
  color: $link-color;
}

.tabs button.active:after {
  border-bottom-color: $link-color;
}

.content {
  flex: 1;
}

.dapp {
  height: 48px;
  display: flex;
  align-items: center;
  font-family: $font;
  font-size: calculateRem(17);
  font-weight: 600;
  padding: 0 calculateRem(12);
}

.dapp .image {
  max-width: 24px;
  max-height: 24px;
  border-radius: 50%;
  margin-right: calculateRem(12);
}

.items {
  display: flex;
  flex-direction: column;
  font-family: $font;
}

.items .itemRow {
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 calculateRem(12);
}

.items .item {
  display: flex;
  align-items: center;
  font-size: calculateRem(15);
  font-weight: 500;
}

.items .item img {
  margin-right: calculateRem(12);
}

.badge {
  border-radius: 24px;
  color: #ffffff;
  font-family: $font;
  font-size: calculateRem(15);
  margin-right: calculateRem(16);
  margin-left: auto;
  padding: calculateRem(3) calculateRem(10);
}

.greenBadge {
  @extend .badge;
  background: #44d058;
}

.redBadge {
  @extend .badge;
  background: #f00;
}

.inputArea {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: calculateRem(24);
  margin-bottom: calculateRem(26);
}

.inputArea.inputAreaBorder {
  border-bottom: 1px solid $link-color;
}

.inputArea input {
  width: 19px;
  border: none;
  text-align: center;
  font-size: calculateRem(32);
  line-height: calculateRem(28);
  font-family: $font;
  caret-color: $link-color;
  margin-right: calculateRem(6);
  background: inherit;
}

input::placeholder {
  color: $headline-color;
}

.inputArea input:focus {
  outline: none;
}

.inputArea::after {
  transition: all 0.05s ease-in-out;
  content: 'PLQ';
  color: inherit;
  font-size: calculateRem(32);
  line-height: calculateRem(28);
  font-family: $font;
}

.inputArea span {
  font-size: calculateRem(32);
  line-height: calculateRem(28);
  font-family: $font;
  margin-right: calculateRem(6);
}

.footer {
  text-align: center;
  flex: 0;
  border-top: 1px solid $link-color;
  height: calculateRem(64);
}

.footer button {
  background: $link-color;
  height: calculateRem(44);
  border-radius: calculateRem(48);
  color: #fff;
  border: none;
  font-family: $font;
  padding: calculateRem(1) calculateRem(48);
  margin: calculateRem(10) calculateRem(120);
  font-size: calculateRem(15);
  cursor: pointer;
}

.footer button:disabled,
.footer button[disabled] {
  background: $text-color;
}

.disclaimer {
  font-size: calculateRem(15);
  color: $background;
  line-height: 22px;
  font-family: $font;
  padding: calculateRem(16);
  margin: 0;
}

.disclaimer a {
  text-decoration: none;
  color: $link-color;
  cursor: pointer;
}

.learnMoreCnt {
  display: flex;
  flex-direction: column;
  font-family: $font;

  .title {
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 17px;
    border-bottom: 1px solid #f7f7f7;
  }

  .spacing {
    margin: 14px 16px;

    img {
      width: 100%;
      flex: 0 0 auto;
    }

    p {
      line-height: 24px;
      text-indent: 32px;
      margin: 0;
    }
  }

  .backButtonCnt {
    height: 64px;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #f7f7f7;
    margin-top: auto;

    .backButton {
      background: $link-color;
      border-radius: 8px;
      color: #fff;
      border: none;
      font-family: $font;
      padding: calculateRem(11) calculateRem(38);
      font-size: calculateRem(15);
      cursor: pointer;
    }
  }
}

@media (min-width: $desktop) {
  .learnMoreCnt {
    min-height: 512px;
  }
}
