@import '../../common/styles/variables';

.header {
  margin: calculateRem(15);
}

.headline {
  font-family: $font;
  color: $headline-color;
  font-size: calculateRem(17);
  margin: 0;
}

.search {
  display: flex;
  width: 100%;
  align-self: center;
  padding: calculateRem(9);
}

@media (max-width: $desktop) {
  .searchResult {
    position: absolute;
    background-color: #ffffff;
  }
}
