@import '../../common/styles/variables';

.header {
  background: $background;
  font-family: 'EudoxusSans';
  display: flex;
  align-items: center;
  padding: calculateRem(15);
  z-index: 99;

  &.active {
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.25);
    position: fixed;
    width: 100%;
    top: 0;
  }
}

.icon {
  margin-right: calculateRem(15);

  svg {
    fill: $headline-color;
  }
}

.text {
  font-size: calculateRem(15);
  margin: 0;
  color: $headline-color;
}
